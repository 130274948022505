import { Modal_Component as ModalComponent } from "../../../../../component";

const AddRecipientModal = ({
  show = false,
  onHide = () => null,
  btnAddRecipient,
  setInput,
  input,
}) => {
  return (
    <ModalComponent
      show={show}
      onHide={onHide}
      title="Add New Recipient"
      btnSubmit
      btnName="Tambah"
      onClick={() => {
        btnAddRecipient();
        onHide();
      }}
      size="md"
    >
      <label htmlFor="" className="form-label">
        Email
      </label>
      <input
        type="email"
        placeholder="Email"
        value={input.email}
        onChange={(e) =>
          setInput((state) => ({ ...state, email: e.target.value }))
        }
        className="form-control"
      />
    </ModalComponent>
  );
};

export default AddRecipientModal;
