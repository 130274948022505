import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import imgBanner1 from "landingPage/assets/iconAndLogo/Asset Banner 2@3x.png";
import imgBanner3 from "landingPage/assets/iconAndLogo/Asset Banner 3@3x.png";
import imgBanner0 from "landingPage/assets/iconAndLogo/Asset Banner 1@3x.svg";
import { Mobile_query_v2 } from "../../util/Query";

const content = [
  {
    image:
      "https://images.unsplash.com/photo-1605457867610-e990b192418e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1426&q=80",
    title: "title 1",
    description: "descriptions 1",
    button: "View",
  },
  {
    image:
      "https://images.unsplash.com/photo-1561424412-6c2125ecb1cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    title: "title 2",
    description: "descriptions 2",
    button: "View",
  },
  {
    image:
      "https://images.unsplash.com/photo-1460176449511-ff5fc8e64c35?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80",
    title: "title 3",
    description: "descriptions 3",
    button: "View",
  },
];

const ListSlide = ({ idx }) => {
  if (idx === 0) {
    return (
      <ContainerSlide0 className="d-flex">
        <div className="left">
          <div className="title">
            An <span>Integrated Digital Engagement Platform</span> for your
            business needs to improve and grow
          </div>
          <div className="wrap-line d-flex">
            <div className="line1 line" />
            <div className="line2 line" />
            <div className="line3 line" />
            <div className="line4 line" />
          </div>
          <div className="desc">
            Create, manage, customize and report using our tools, all in one
            platform.
          </div>
        </div>
        <div className="right">
          <img src={imgBanner0} alt="" />
        </div>
      </ContainerSlide0>
    );
  } else if (idx === 1) {
    return (
      <ContainerSlide1 className="d-flex">
        <div className="left">
          <div className="text-big">
            We have omni-channel inventories for Companies, Brand Owners and
            SMEs to choose
          </div>
          <div className="desc">
            Able to bring your products/brand on-board digitally and monetize
            directly (on the go)
          </div>
        </div>
        <div className="right">
          <img src={imgBanner1} alt={imgBanner1} />
        </div>
      </ContainerSlide1>
    );
  } else if (idx === 2) {
    return (
      <ContainerSlide2 className="d-flex">
        <div className="right">
          <div className="title">Integrated Reporting System </div>
          <div className="desc">
            Provides an objectives campaign analysis highlights favorable
            results and identifies areas in which advertising strategy
            adjustments are appropriate
          </div>
          <div className="wrap-button d-flex gap-3 mt-3">
            <button className="get-started">Get Started</button>
            <button className="view-detail">View Detail</button>
          </div>
        </div>
        <div className="left">
          <img src={imgBanner3} alt={imgBanner3} />
        </div>
      </ContainerSlide2>
    );
  } else {
    return <div></div>;
  }
};

const SliderCarousel = () => {
  return (
    <div style={{ marginTop: "36px" }}>
      <Carousel>
        {content.map((item, idx) => (
          <Carousel.Item key={idx}>
            <ListSlide idx={idx} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default SliderCarousel;

const ContainerSlide0 = styled.div`
  align-items: center;
  min-height: 390px;
  ${Mobile_query_v2} {
    flex-direction: column;
    .left > .wrap-line {
      display: none !important;
    }
    .left > .title,
    .desc {
      text-align: center;
    }

    .right > img {
      width: 373px;
    }
  }
  .left > .desc {
    margin-top: 28px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    /* or 180% */

    color: rgba(12, 27, 77, 0.6);
  }

  .left > .wrap-line {
    margin-top: 31px;
  }

  .left > .wrap-line > .line1 {
    width: 14.72px !important;
  }
  .left > .wrap-line > .line2 {
    width: 48.77px !important;
  }
  .left > .wrap-line > .line3 {
    width: 22.12px !important;
  }
  .left > .wrap-line > .line4 {
    width: 142px !important;
  }
  .left > .wrap-line > .line {
    width: 14.72px;
    height: 3px;
    background-color: #2fbeee;
    border-radius: 5px;
    margin-right: 8px;
  }
  .left > .title > span {
    color: #2fbeee;
  }
  .left > .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;

    /* Primary */

    color: #3b3e98;
  }
  .left,
  .right {
    flex: 1;
  }
`;

const ContainerSlide2 = styled.div`
  align-items: center;
  min-height: 390px;
  ${Mobile_query_v2} {
    flex-direction: column;

    .right {
      .title {
        text-align: center;
      }
      .desc {
        text-align: center;
      }

      .wrap-button {
        justify-content: center;
      }
    }

    .left {
      img {
        width: 366px;
      }
    }
  }
  .left > img {
  }

  .right > .wrap-button > .view-detail {
    border: 1px solid #3b3e98;
    border-radius: 6px;
    background: transparent;
    width: 175px;
    height: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #3b3e98;
  }
  .right > .wrap-button > .get-started {
    background: #3b3e98;
    border-radius: 6px;
    width: 175px;
    height: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    border: thin solid #3b3e98;
  }

  .right > .desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */

    /* Black 3 */

    color: rgba(12, 27, 77, 0.6);
  }

  .right > .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    /* Primary */

    color: #3b3e98;
  }
`;

const ContainerSlide1 = styled.div`
  align-items: center;
  min-height: 390px;
  ${Mobile_query_v2} {
    flex-direction: column;

    .left {
      .text-big,
      .desc {
        text-align: center;
      }
    }

    .right > img {
      width: 373px;
    }
  }
  .left,
  .right {
    flex: 1;
  }

  .right > img {
    width: 515px;
    height: 355px;
  }

  .left > .text-big {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;

    /* Primary */

    color: #3b3e98;
  }

  .left > .desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    /* or 200% */

    /* Black 3 */

    color: rgba(12, 27, 77, 0.6);
  }
`;
