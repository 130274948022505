import React from "react";
import styled from "styled-components";
import Img1 from "landingPage/assets/iconAndLogo/img1.png";
import Img2 from "landingPage/assets/iconAndLogo/img2.png";
import Img3 from "landingPage/assets/iconAndLogo/omg3.png";
import Img4 from "landingPage/assets/iconAndLogo/img5.jpeg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const listTesti = [
  {
    img: Img4,
    name: "Jessica",
    jabatan: "Owner Agaya Resto & Cafe Semarang",
    desc: "Agaya Cafe & Eatery baru buka beberapa bulan, walau lokasi kami bukan di jalan utama tapi Puji Tuhan resto kami mulai ramai karena mendapat informasi dari SMS Blast yang kami kirimkan. Selain customer yang datang, transaksi online juga meningkat karena kami memberika free delivery utk radius 5km dr resto, hal ini karena sistem dari Medicartz kita setting sendiri radius SMS yg akan kita blast. ~Jessica",
  },
  {
    img: Img1,
    name: "Pang Xue Kai",
    jabatan: "CEO & Co-Founder Tokocrypto",
    desc: "Deepest and heartful thanks to Mediacartz for powering Tokocrypto, ensuring and making sure that all of our services are smooth and lancar. Terima kasih banyak dan salam to the moon.",
  },
  {
    img: Img2,
    name: "Eric Ong",
    jabatan: "Director of Panin Dubai Syariah Bank",
    desc: "Bekerja sama dengan Mediacartz dalam memasarkan salah satu produk dari usaha kami adalah pilihan yang tepat. Selain membantu kami dalam ekspansi usaha, juga tingkat responsif pelayanan yang sangat mudah, cepat, dan kooperatif. Harapan kami, usaha kami akan berkembang dengan cepat Bersama dengan mediacartz. Maju Terus! ",
  },
  {
    img: Img3,
    name: "Agus Mulyo Pribadi",
    jabatan: "Category Channel Sales Lead - Blibli.com",
    desc: "Mediacarzt adalah solusi terbaik bagi UMKM, tidak hanya untuk promosi produk namun juga mempromosikan tempat berjualannya dan pelakunya. dengan berbagai pilihan channel yang dapat disesuaikan dengan kebutuhan para pelaku UMKM, mediacarzt sangat memudahkan pelaku UMKM untuk berpromosi dengan tepat.",
  },
];

const CardTesti = ({ item = {} }) => {
  return (
    <ContainerCard className="item">
      <div className="testi-desc">{item.desc}</div>
      <div className="wrap-profil-testi d-flex gap-3 align-items-center mt-2">
        <img src={item.img} alt={item.img} />
        <div className="wrap-profil-testi-text">
          <div className="title-testi-text">{item.name}</div>
          <div className="small-title-testi-text">{item.jabatan}</div>
        </div>
      </div>
    </ContainerCard>
  );
};

const Testimonials = () => {
  return (
    <Container>
      <div className="container">
        <div className="info-testi">Testimonials</div>
        <div className="desc-testi">
          What our users are saying about Mediazartz with our services and we
          love them
        </div>
      </div>
      <div className="wrap-owl-carousel" style={{ marginTop: "48px" }}>
        <OwlCarousel className="owl-theme" margin={10} nav dots={false}>
          {listTesti.map((item, idx) => (
            <CardTesti key={idx} item={item} />
          ))}
        </OwlCarousel>
      </div>
    </Container>
  );
};

export default Testimonials;

const ContainerCard = styled.div`
  .testi-desc {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    /* Neutral/600 */

    color: #6f6c90;
    width: 413px;
    height: 210px;
    overflow: auto;
  }

  .wrap-profil-testi {
    img {
      width: 63.7px;
      height: 60px;
      object-fit: cover;
      border-radius: 100%;
    }
    .title-testi-text {
      /* Body/Large - Bold */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */

      /* Neutral/800 */

      color: #170f49;
    }

    .small-title-testi-text {
      /* Body/Normal - Regular */

      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      /* identical to box height, or 162% */

      /* Neutral/600 */

      color: #6f6c90;
    }
  }

  /* Neutral/100 */

  background: #ffffff;
  /* Neutral/300 */

  border: 1px solid #eff0f7;
  /* Cards/Short Default */

  box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
  border-radius: 20px;

  width: 500px;
  height: 355px;
  padding: 38px 43px 39px 44px;
`;

const Container = styled.div`
  .info-testi {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;

    /* Brand Color/Primary */

    color: #3b3e98;
  }

  .desc-testi {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    /* Black 3 */

    color: rgba(12, 27, 77, 0.6);
    max-width: 402px;
  }

  .wrap-owl-carousel {
    .owl-carousel {
      position: relative !important;
      .owl-nav {
        position: absolute !important;
        top: 40% !important;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        padding: 20px !important;
        overflow: hidden;
        .disabled {
          opacity: 0 !important;
        }
        .owl-prev,
        .owl-next {
          width: 56px !important;
          height: 56px !important;
          background: #3b3e98;
          box-shadow: 0px 4px 4px rgba(12, 4, 102, 0.2);
          color: #ffff;
          border-radius: 100%;
          span {
            font-size: 20px !important;
          }
        }
      }
      .owl-stage-outer {
        padding-left: 250px !important;
        .owl-stage {
          .owl-item {
            width: auto !important;
          }
        }
      }
    }
  }
`;
