import React, { useEffect, useState } from "react";
import * as Util from "../../../util";
import styled from "styled-components";
import * as Style from "../../../component/style/content/default";
import { Create } from "@styled-icons/ionicons-outline";

const CardProduct = (props: { menu: any, onClickDetail: Function }) => {
  const { menu, onClickDetail, menuData } = props;
  const [suggest, setSuggest] = useState('')

  useEffect(()=>{
		if(menu.bundling_item && menu.bundling_item.length > 0){
			var mergeData = []
			for(var i=0; i<Object.keys(menuData).length;i++){
				mergeData = [...mergeData, ...menuData[Object.keys(menuData)[i]]]
			}

			var _suggest = '';
			for(var j=0; j<menu.bundling_item.length; j++){
				if(_suggest!==0){
					const _item = mergeData.find( x => x.item_id === menu.bundling_item[j].item_id)
					if(_item){
						const divide = Math.floor(_item.menu_current_quantity/parseInt(menu.bundling_item[j].quantity))
						if(_suggest==='' || divide <= _suggest){
							_suggest = divide
						}
					}else{
						_suggest = 0
					}
				}
			}

			setSuggest(_suggest)
		}
	}, [menuData, menu])

  return (
    <Container className="card">
      <div className="image">
        {
					menu?.bundling_item && menu?.bundling_item.length > 0 &&
          <div className="badge-bundling">Bundling</div>
        }
        <img src={menu?.item_image[0]} alt={menu?.item_name} />
        {
					menu?.bundling_item && menu?.bundling_item.length > 0 ? (
            <div className="badge-angka warning">
              Suggest: {Util.FormatCurrency?.input(suggest)} 
            </div>
          ) : (
            <div className={`badge-angka ${menu?.menu_current_quantity===0?'red':'green'}`}>
              Stock : {Util.FormatCurrency?.input(menu?.menu_current_quantity)}
            </div>    
          )
        }
      </div>
      <div className="title p-2">{menu?.item_name}</div>
      {menu?.discount_price && (
        <div className="discount-price">
          {Util.FormatCurrency.currency(menu?.regular_price)}
        </div>
      )}
      <div className="price">
        {menu?.discount_price
          ? Util.FormatCurrency.currency(menu?.discount_price)
          : Util.FormatCurrency.currency(menu?.regular_price)}
      </div>

      {/* buat hover */}
      <div className="container-hover d-flex justify-content-center align-items-center">
        <Create width={25} cursor="pointer" onClick={()=>onClickDetail(suggest)} />
      </div>
    </Container>
  );
};

export default CardProduct;

const Container = styled.div`
  /* CSS hover */

  .discount-price {
    text-decoration: line-through;
    font-style: italic;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 10pt;
  }
  .price {
    margin-bottom: 5px;
    font-weight: 700;
    color: ${Style.COLOR_COKLAT};
  }
  .image {
    width: 100%;
    height: 180px;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    &:hover ~ .container-hover {
      bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .badge-angka {
      color: #fff;
      position: absolute;
      /* width: 80px; */
      bottom: 0px;
      border-top-right-radius: 10px;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 1px;
      padding: 2px 7px;
    }
    .badge-bundling {
      color: #fff;
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 10px;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 1px;
      padding: 2px 7px;
      background-color: #2e3894;
    }
    .green{
      background-color: #28a745;
    }
    .red{
      background-color: #dc3545;
    }
    .warning{
      background-color: #ffc107;
    }
  }

  .title {
    font-weight: 600;
    font-size: 12pt;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  .container-hover {
    position: absolute;
    height: 110px;
    /* border: thin solid #ccc; */
    padding: 5px;
    width: 100%;
    bottom: -110px;
    left: 0;
    background-color: #fff;
    transition: 250ms;
    &:hover {
      bottom: 0;
    }
  }
  position: relative;
  overflow: hidden;
`;
