import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import * as Components from "../../../component";
import * as Style from "../../../component/style/content/default";
import * as Services from "../../../service";
import * as Util from "../../../util";
import styled from "styled-components";
import * as Modal from "./modal";
import { Context, Method } from "../../../service";
import {
  Whatsapp,
  Instagram,
  Facebook,
  Twitter,
  Youtube,
} from "@styled-icons/boxicons-logos";
import { Email } from "@styled-icons/material-outlined";
import api_permission from "service/api/permission";
import { Col, Row } from "react-bootstrap";

const api = Services.api.ApiRetail.toko;
export default function Toko() {
  const { selected_company, dispatch, retail_permission, data_user } = useContext(Services.Context);
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  });
  const [modal, setModal] = useState({
    company: false,
    detail: false,
    create: false
  });
  const [dataProps, setDataProps] = useState({});

  useEffect(() => {
    if(selected_company.value){
      api_permission(dispatch, selected_company.value, data_user.id)
    }
  }, [selected_company])

  const GetList = async (page) => {
    if (selected_company?.value) {
      await api.getListToko(selected_company?.value, page).then((res) => {
        if (res?.success) {
          setData((state) => ({
            ...state,
            data: res?.data,
            page: res?.page,
            lastPage: res?.last_page,
          }));
        }
      });
    }
  };

  const GetCompany = async () => {
    await Method.get(`company`)
      .then(async (res) => {
        if (res?.data?.success) {
          if (!selected_company.value) {
            dispatch({
              type: "SELECTED_COMPANY",
              selected_company: {
                value: res?.data?.data[0]?.company_id,
                label: res?.data?.data[0]?.company_name,
              },
            });
          }
        } else {
          Components.AlertError({ title: "Error", text: res?.data?.error });
        }
      })
    // .catch((err) => {
    // });
  };

  useEffect(() => {
    GetCompany();
  }, []);

  useEffect(() => {
    if (selected_company.value) {
      GetList(data?.page);
    }
  }, [selected_company.value]);

  const BtnPagination = async (e) => {
    await GetList(e.selected + 1);
  };

  const BtnDetail = async (store_id) => {
    await Promise.all([
      api.getTokoDetalMenu(store_id).then((res) => {
        if (res?.success) {
          setDataProps(res?.data);
        }
      }),
      setModal((state) => ({ ...state, detail: true })),
    ]);
  };


  const changeStatus = async () => {
    var status = '';
    if (dataProps.store.store_active_status === 1) {
      status = 'inactivate';
    } else {
      status = 'activate';
    }

    await api.putTokoStatus(dataProps.store.store_id, status).then((res) => {
      if (res?.success) {
        BtnDetail(dataProps.store.store_id);
      } else {
        Components.AlertError({ title: "Error", text: res?.error });
      }
    }).catch((err) => {
    })
  }

  const editStore = () => {
    setModal((state) => ({ ...state, create: true, detail: false }))
  }

  return (
    <Style.ContainerContent>
      {/* MODAL =========== */}
      <Modal.ModalSelectCompany
        show={modal.company}
        onHide={() => setModal((state) => ({ ...state, company: false }))}
      />

      <Modal.ModalDetailToko
        show={modal.detail}
        onHide={() => setModal((state) => ({ ...state, detail: false }))}
        dataProps={dataProps}
        onEdit={() => editStore()}
        onStatusChange={() => changeStatus()}
      />

      <Modal.ModalTambahToko
        show={modal.create}
        data={dataProps.store ? dataProps.store : null}
        onHide={() => setModal((state) => ({ ...state, create: false, detail: dataProps.store ? true : false }))}
        onSubmit={() => GetList()}
        onEdit={() => BtnDetail(dataProps.store.store_id)}
      />

      <div className="d-flex">
        <Style.HeaderPrimary color={Style.COLOR_PRIMARY}>
          Daftar Toko
        </Style.HeaderPrimary>
        <div className="wrap-select align-items-center ms-5">
          <button
            className="btn border"
            onClick={() => setModal((state) => ({ ...state, company: true }))}
          >
            {selected_company.label ? selected_company.label : 'Select Company'} <i className="demo-icon icon-chevron-down"></i>
          </button>
        </div>
      </div>

      {
        retail_permission.data===null || retail_permission.data?.type!=='staff'?(
          <div className="mt-lg-5">
            <div className="wrap-select d-flex align-items-center gap-2">
              <Style.ButtonPrimary
                onClick={() => {
                  setDataProps({})
                  setTimeout(() => {
                    setModal((state) => ({ ...state, create: true }))
                  }, 500)
                }}
              >
                Tambah Toko
              </Style.ButtonPrimary>
            </div>

            {
              data?.data.length > 0 &&
              <Row className="mt-4">
                {
                  data?.data.map((item, idx) => (
                    <Col xs={12} md={4} lg={2} key={idx} className="mb-3">
                      <Card>
                        <div className="wrap-image">
                          <img src={item.store_logo} alt={item.store_name} />
                        </div>
                        <div className="wrap-title mb-2 mt-2">
                          <strong>{item.store_name}</strong>
                        </div>
                        <div className="wrap-city">
                          <small>
                            <div>{item.store_address}</div>
                          </small>
                        </div>
                        <div className="wrap-contact mt-4 mb-4">
                          {item?.store_phone && (
                            <Row>
                              <Col xs={2}>
                                <Whatsapp width={20} height={20} color="gray" />
                              </Col>
                              <Col xs={10}>
                                <div className="desc-card">{item.store_phone}</div>
                              </Col>
                            </Row>
                          )}
                          {item?.store_email && (
                            <Row>
                              <Col xs={2}>
                                <Email width={20} height={20} color="gray" />
                              </Col>
                              <Col xs={10}>
                                <div className="desc-card">{item.store_email}</div>
                              </Col>
                            </Row>
                          )}
                          {item?.store_instagram && (
                            <Row>
                              <Col xs={2}>
                                <Instagram width={20} height={20} color="gray" />
                              </Col>
                              <Col xs={10}>
                                <div className="desc-card">{item.store_instagram}</div>
                              </Col>
                            </Row>
                          )}
                          {item?.store_facebook && (
                            <Row>
                              <Col xs={2}>
                                <Facebook width={20} height={20} color="gray" />
                              </Col>
                              <Col xs={10}>
                                <div className="desc-card">{item.store_facebook}</div>
                              </Col>
                            </Row>
                          )}
                          {item?.store_twitter && (
                            <Row>
                              <Col xs={2}>
                                <Twitter width={20} height={20} color="gray" />
                              </Col>
                              <Col xs={10}>
                                <div className="desc-card">{item.store_twitter}</div>
                              </Col>
                            </Row>
                          )}
                          {item?.store_youtube && (
                            <Row>
                              <Col xs={2}>
                                <Youtube width={20} height={20} color="gray" />
                              </Col>
                              <Col xs={10}>
                                <div className="desc-card">{item.store_youtube}</div>
                              </Col>
                            </Row>
                          )}
                        </div>

                        <div className="wrap-button">
                          <a href="#" onClick={() => BtnDetail(item.store_id)}>
                            View Detail
                          </a>
                        </div>
                      </Card>
                    </Col>
                  ))
                }
              </Row>

            }

            <div className="wrap-pagination mt-5">
              {data?.data?.length > 0 && (
                <Components.Pagination
                  totalPage={data?.lastPage}
                  page={data?.page}
                  handleOnChange={BtnPagination}
                />
              )}
            </div>
          </div>
        ):(
          <div class="alert alert-warning mt-lg-5" role="alert">
            <h5>Akses Ditolak</h5>
            Anda sebagai staff tidak mempunyai akses di perusahaan ini
          </div>
        )
      }

    </Style.ContainerContent>
  );
}

const Card = styled.div`
background-color: #fff;
  width: 100%;
  padding: 20px 20px 10px;
  box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 34%);
  transition: 250ms;
  border-radius: 25px;
  height: 100%;

  .desc-card{
    word-wrap: break-word;
  }

  .wrap-button {
    text-align:center;
    a {
      font-size: 10pt;
      text-decoration: underline;
      &:hover {
        color: ${Style.COLOR_SECONDARY};
      }
    }
  }
  .wrap-contact {
    font-size: 10pt;
    color: gray;
  }
  .wrap-city {
    min-height: 50px;
    text-align: center;
    div {
      font-size: 8pt;
      color: #737373;
    }
  }
  .wrap-title {
    text-align: center;
    strong {
      font-weight: 600;
    }
  }
  .wrap-image {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 9999px;
    border: thin solid #ccc;
    overflow: hidden;
    margin: auto;
    img {
      width: 100%;
    }
  }
  &:hover {
    transform: translateY(-5%);
  }
`;
